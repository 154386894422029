<template>
	<div class="common_tab">
		<div @click="changeTab(item)" :class="{'tab_active': currentTab == item.label}" class="common_tab_item"
			v-for="(item, index) in moduleList" :key="index">
			{{item.label}}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			currentTab: {
				type: String,
				default: function() {
					return ""
				}
			},
			moduleList: {
				type: Array,
				default: function() {
					return []
				}
			},
		},
		data() {
			return {
				
			}
		},
		methods: {
			changeTab(item) {
				this.$emit("changeTab", item)
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	.common_tab {
		display: flex;
		align-items: center;
		margin: 10px 0;

		.common_tab_item {
			background: #FFFFFF;
			border-radius: 18px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			text-align: center;
			cursor: pointer;
			margin-right: 10px;
			box-sizing: border-box;
			user-select: none;
			width: 160px;
			box-shadow: 0px 3px 6px rgba(91, 168, 151, 0.2);
			line-height: 36px;
			font-size: 16px;
			color: #999999;

			&.tab_active {
				border: 1px solid $theme_color;
				color: $theme_color;
			}
		}
	}
</style>
