<template>
	<div class="upLoadFile">
		<div class="upLoadFile_list">
			<div v-if="fileData.length > 0" class="flex-item">
				<div v-for="(item,index) in fileData" :key="index" class="upLoadFile_list_item" :class="{'w240':picType=='school'}">
					<el-image
						fit="cover"
						class="upLoadFile_list_img"
						:class="{'w240':picType=='school'}"
						:src="item" 
						:preview-src-list="fileData">
					</el-image>
					<!-- <img :src="item" class="upLoadFile_list_img" @click="handlePictureCardPreview(item)"> -->
					<span @click="removeImg(index)" class="upLoadFile_list_delete"><i class="el-icon-delete"></i></span>
				</div>
			</div>
			<div v-if="fileData.length < limitUpLoad" class="upLoadFile_list_item" :class="{'w240':picType=='school'}">
				<img src="@/assets/images/common/upLoad.png" class="upLoadFile_list_icon">
				<span class="upLoadFile_list_text">点击上传</span>
				<span class="upLoadFile_list_tip">{{fileTip}}</span>
				<el-upload class="upLoadFile_list_btn" drag 
					:before-upload="beforeUpload" 
					:action="upLoadUrl"
					></el-upload>
			</div>
		</div>
		 <el-dialog :modal="false" class="dialog_bg" :visible.sync="dialogVisible" :append-to-body="false" :modal-append-to-body="false" :width="dialogWidth">
			<img width="100%" :src="imgSrc">
		</el-dialog>
	</div>
</template>

<script>
import { getImgSrc, upLoadFile } from "@/api/OSS";
export default {
  props: {
    limitSize: {
      //限制多少MB
      type: Number,
      default: 5
    },
    limitUpLoad: {
      //限制多少MB
      type: Number,
      default: 1
    },
    fileList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    dialogWidth: {
      type: String,
      default: "50%"
    },
    fileTip: {
      type: String,
      default: function() {
        return "只能上传jpg/png";
      }
    },
    picType: {
      type: String,
      default: function() {
        return "";
      }
    },
    isLimitPx: {
      type: Boolean,
      dafault: function() {
        return false;
      }
    }
  },
  data() {
    return {
      upLoadUrl: "",
      dialogVisible: false,
      fileData: [],
      imgSrc: ""
    };
  },
  watch: {
    fileList: {
      handler(val) {
        console.log("fileList", val);
        this.fileData = [];
        this.fileData = val;
      },
      deep: true
    }
  },
  methods: {
    removeImg(index) {
      this.fileData.splice(index, 1);
      this.$emit("removeImg", this.fileData);
    },
    // 获取图片宽高信息
    getImgViews(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function(e) {
          let txt = e.target.result;
          let img = document.createElement("img");
          img.src = txt;
          img.onload = function() {
            resolve({
              imgWidth: img.width,
              imgHeight: img.height
            });
          };
        };
        reader.readAsDataURL(file);
      });
    },
    // 上传按钮   限制图片大小
    async beforeUpload(file) {
      console.log("beforeUpload", file);
      if (this.isLimitPx) {
        let imgViews = {};
        imgViews = await this.getImgViews(file);
        console.log("图片宽高", imgViews);
        if (this.picType == "school") {
          if (
            imgViews.imgWidth < 80 ||
            imgViews.imgHeight < 80 ||
            imgViews.imgWidth / imgViews.imgHeight != 1
          ) {
            this.$message.error("上传图片的宽高比例不对！");
            return false;
          }
        } else {
          if (
            imgViews.imgWidth < 340 ||
            imgViews.imgHeight < 170 ||
            imgViews.imgWidth / imgViews.imgHeight != 2
          ) {
            this.$message.error("上传图片的宽高比例不对！");
            return false;
          }
        }
      }
      // const isLt5M = file.size / 1024 / 1024 < this.limitSize;
      // if (!isLt5M) {
      // 	this.$message.error("上传文件大小不能超过 5MB!");
      // 	return false;
      // }
      let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      let fileName = file.name;
      let fileType = file.type;
      if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
        this.$message({
          message: "上传文件只能是jpg、png格式!",
          type: "warning"
        });
        return false;
      }
      getImgSrc({
        fileName: fileName
      }).then(res => {
        if (res.code != 0) return;
        this.upLoadUrl = res.data.uploadUrl;
        console.log("获取文件上传地址>>>>>111", res.data.uploadUrl);
        upLoadFile(file, this.upLoadUrl, fileType).then(res => {
          const fileUrl = res.config.url.substring(
            0,
            res.config.url.indexOf("?")
          );
          if (this.limitUpLoad == 1) {
            this.fileData = [];
          }
          this.fileData.push(fileUrl);
          console.log("文件上传成功fileData>>>>>", res);
          this.$emit("beforeUpload", this.fileData);
        });
      });
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.imgSrc = file;
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/index.scss";
/deep/.el-upload-dragger {
  width: 240px;
  height: 140px;
  border-radius: 4px;
}

.flex-item {
  display: flex;
  align-items: center;
}
.w240 {
  width: 140px !important;
}
.upLoadFile {
  .upLoadFile_list {
    display: flex;
    align-items: center;

    .upLoadFile_list_item {
      width: 240px;
      height: 140px;
      background: #f5f5f5;
      border-radius: 4px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .upLoadFile_list_icon {
        width: 50px;
        height: 50px;
      }

      .upLoadFile_list_text {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: $theme_color;
        margin: 6px 0 3px 0;
      }

      .upLoadFile_list_tip {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 17px;
        padding: 0 10px;
        color: #999999;
        text-align: center;
      }

      .upLoadFile_list_btn {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
      }

      .upLoadFile_list_img {
        width: 240px;
        height: 140px;
        border-radius: 4px;
      }

      .upLoadFile_list_delete {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0px 4px 0px 0px;
        color: #ffffff;
      }
    }
  }
}
.dialog_bg /deep/ .el-dialog__header {
  padding: 0;
}
.dialog_bg /deep/ .el-dialog__body {
  padding: 20px;
}
</style>
